$base-font-size: 1em!default;
$button-scroll-right: 15px!default;
$button-scroll-size: 50px;

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number;
  }

  @return $number;
}
@function rem($size, $base: $base-font-size) {
  @return calc(1rem * abs($size) / strip-unit($base))
}
@function em($size, $base: $base-font-size) {
  @return calc(1em * abs($size) / strip-unit($base))
}
