@import "sweetalert2/dist/sweetalert2.css";
@import "~boxicons/css/boxicons.css";
@import "~aos/dist/aos.css";
@import "highlight.js/styles/monokai-sublime.css";
a {
  text-decoration: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: AlegreyaSans-Regular, sans-serif;
  font-size: 1em;
}
*:focus {
  outline: none !important;
}

body {
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='69.141' height='40' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M69.212 40H46.118L34.57 20 46.118 0h23.094l11.547 20zM57.665 60H34.57L23.023 40 34.57 20h23.095l11.547 20zm0-40H34.57L23.023 0 34.57-20h23.095L69.212 0zM34.57 60H11.476L-.07 40l11.547-20h23.095l11.547 20zm0-40H11.476L-.07 0l11.547-20h23.095L46.118 0zM23.023 40H-.07l-11.547-20L-.07 0h23.094L34.57 20z'  stroke-width='1' stroke='hsla(259, 0%, 94%, 0.64)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
}

#mention-border {
  border: 1px solid black;
  padding: 1rem;
}

::before,
::after {
  box-sizing: border-box;
}

html {
  height: 100vh;
  scroll-behavior: smooth;
}

.border-50 {
  border-radius: 50%;
}

.logo {
  width: 20em;
}

.text-slider-items {
  display: none;
}

#textProfil p {
  color: black !important;
}

.section {
  overflow-x: hidden;
  margin-bottom: 5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.no-x-overflow {
  overflow-x: hidden !important;
}

/* --- Modifier le style de la barre de defilement --- */
::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px #3F5160;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 2px black;
  background-color: #3F5160;
}

.bx-xl {
  font-size: 5rem !important;
}

#form-contact {
  padding: 1rem;
}
#form-contact input, #form-contact select, #form-contact textarea {
  border-radius: 0 !important;
  outline: none;
  box-shadow: none;
}
#form-contact input:focus, #form-contact select:focus, #form-contact textarea:focus {
  border: 3px solid #3F5160;
}

/* --- Scroll to top  button --- */
.scroll-to-top {
  position: fixed;
  display: none;
  right: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  bottom: 15px;
  color: white;
  background: rgba(23, 24, 25, 0.5);
  line-height: 2.8;
  z-index: 5;
}
.scroll-to-top:focus {
  color: white;
}
.scroll-to-top:hover {
  color: white;
  background: #171819;
}
.scroll-to-top i {
  font-weight: 800;
}

.center-box {
  display: block;
  width: 58%;
  max-width: 925px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .center-box {
    width: 78%;
  }
}
@media screen and (max-width: 767px) {
  .center-box {
    width: 85%;
  }

  .section {
    margin-top: 6rem;
  }
}
@media screen and (max-width: 479px) {
  .center-box {
    width: 90%;
  }

  .border-50 {
    border-radius: 50%;
    height: 18rem;
    width: 18rem;
  }
}
.badge-certification {
  width: 10rem;
  height: 10rem;
  margin: 1rem 0;
}

.row > * a {
  width: unset !important;
}

.content-wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-width: 75vw;
  min-height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.content-wrapper .flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

h1 {
  margin-bottom: 3rem;
}

body {
  position: relative;
  padding: 3rem 1rem 0 1rem;
  font-family: sans-serif;
  transition: 0.5s;
  min-width: 320px;
  overflow-x: hidden;
  min-height: 100vh;
  background-attachment: fixed;
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='69.141' height='40' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M69.212 40H46.118L34.57 20 46.118 0h23.094l11.547 20zM57.665 60H34.57L23.023 40 34.57 20h23.095l11.547 20zm0-40H34.57L23.023 0 34.57-20h23.095L69.212 0zM34.57 60H11.476L-.07 40l11.547-20h23.095l11.547 20zm0-40H11.476L-.07 0l11.547-20h23.095L46.118 0zM23.023 40H-.07l-11.547-20L-.07 0h23.094L34.57 20z'  stroke-width='1' stroke='hsla(0, 0%, 0%, 0.05)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
}

a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #D8D8D9;
  z-index: 100;
  transition: 0.5s;
}

.header_toggle {
  color: #3F5160;
  font-size: 1.5rem;
  cursor: pointer;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: 4.25rem;
  height: 100vh;
  background-color: #3F5160;
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: 100;
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 2rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: white;
}

.nav_logo-name {
  color: white;
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: #171819;
  margin-bottom: 1.5rem;
  transition: 0.3s;
}
.nav_link:hover {
  color: #FFFFFFE6;
}

.nav_icon {
  font-size: 1.25rem;
}

.show {
  left: 0;
}

.body-pd {
  padding-left: 5.25rem;
}

.active {
  color: white;
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: white;
}

.height-100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  body {
    padding-top: 4rem;
    padding-left: 6.25rem;
  }

  .header {
    height: 4rem;
    padding: 0 2rem 0 6.25rem;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  .show {
    width: 14rem;
  }

  .body-pd {
    padding-left: 16rem;
  }
}
/* --- Scroll to top  button --- */
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 10rem !important;
  color: white;
  background: rgba(52, 58, 64, 0.5);
  line-height: 45px;
  z-index: 5;
}

.scroll-to-top:focus, .scroll-to-top:hover {
  color: white;
}

.scroll-to-top:hover {
  background: #343a40;
}

.scroll-to-top i {
  font-weight: 800;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #Fff;
  animation: loader 2s infinite ease;
}

.loader-wrapper {
  z-index: 1000;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3F5160;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
#dots5 {
  display: block;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  position: absolute;
  top: 50%;
  width: 50px;
}
#dots5 span {
  -webkit-animation: dots5 1s ease-in-out infinite;
  animation: dots5 1s ease-in-out infinite;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
}
#dots5 span:first-child {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 0;
}
#dots5 span:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 15px;
}
#dots5 span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 30px;
}
#dots5 span:nth-child(4) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: 45px;
}

@keyframes dots5 {
  0% {
    background: #f4f0ec;
    transform: translateY(0);
  }
  25% {
    background: #f0c0b2;
    transform: translateY(10px);
  }
  50% {
    background: #a5d4fa;
    transform: translateY(10px);
  }
  to {
    background: #b3bac4;
    transform: translateY(0);
  }
}
@-webkit-keyframes dots5 {
  0% {
    background: #f4f0ec;
    transform: translateY(0);
  }
  25% {
    background: #f0c0b2;
    transform: translateY(10px);
  }
  50% {
    background: #a5d4fa;
    transform: translateY(10px);
  }
  to {
    background: #b3bac4;
    transform: translateY(0);
  }
}
.theme-skillred .card-item-top {
  border-top: 0.5em solid #e70f16;
}
.theme-skilllightblue .card-item-top {
  border-top: 0.5em solid #6495ed;
}
.theme-skillblue .card-item-top {
  border-top: 0.5em solid #49b0f0;
}
.theme-skillgray .card-item-top {
  border-top: 0.5em solid #878e93;
}
.theme-skillmediumblue .card-item-top {
  border-top: 0.5em solid #0872c4;
}
.theme-skillyellow .card-item-top {
  border-top: 0.5em solid #ffc107;
}
.theme-skillorange .card-item-top {
  border-top: 0.5em solid #fa3f06;
}
.theme-skillgreen .card-item-top {
  border-top: 0.5em solid #50bd13;
}
.theme-skillpurple .card-item-top {
  border-top: 0.5em solid #b447eb;
}
.theme-skillpink .card-item-top {
  border-top: 0.5em solid #ff24d0;
}
.theme-skilldarkblue .card-item-top {
  border-top: 0.5em solid #183e65;
}
.theme-skillblack .card-item-top {
  border-top: 0.5em solid #212529;
}

.card-icon {
  font-size: 5rem !important;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.theme-skillred .card-icon {
  color: #e70f16;
}
.theme-skilllightblue .card-icon {
  color: #6495ed;
}
.theme-skillblue .card-icon {
  color: #49b0f0;
}
.theme-skillgray .card-icon {
  color: #878e93;
}
.theme-skillmediumblue .card-icon {
  color: #0872c4;
}
.theme-skillyellow .card-icon {
  color: #ffc107;
}
.theme-skillorange .card-icon {
  color: #fa3f06;
}
.theme-skillgreen .card-icon {
  color: #50bd13;
}
.theme-skillpurple .card-icon {
  color: #b447eb;
}
.theme-skillpink .card-icon {
  color: #ff24d0;
}
.theme-skilldarkblue .card-icon {
  color: #183e65;
}
.theme-skillblack .card-icon {
  color: #212529;
}

.skill_item {
  border-top: 0.5em solid #e70f16;
  padding: 60px 30px;
  text-align: center;
  background: #f8faff;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.skill_item img {
  margin-bottom: 2.5rem;
}
.skill_item h2 {
  color: #05364d;
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 1.25rem;
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.skill_item p {
  margin-bottom: 0rem;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.skill_item:hover {
  transition: all 0.4s ease 0s;
}
.skill_item:hover h2 {
  color: #ffffff;
}
.skill_item:hover p {
  color: #ffffff;
}
.skill_item:hover .card-icon {
  color: #ffffff;
}
.theme-skillred .skill_item:hover {
  background: #e70f16;
}
.theme-skilllightblue .skill_item:hover {
  background: #6495ed;
}
.theme-skillblue .skill_item:hover {
  background: #49b0f0;
}
.theme-skillgray .skill_item:hover {
  background: #878e93;
}
.theme-skillmediumblue .skill_item:hover {
  background: #0872c4;
}
.theme-skillyellow .skill_item:hover {
  background: #ffc107;
}
.theme-skillorange .skill_item:hover {
  background: #fa3f06;
}
.theme-skillgreen .skill_item:hover {
  background: #50bd13;
}
.theme-skillpurple .skill_item:hover {
  background: #b447eb;
}
.theme-skillpink .skill_item:hover {
  background: #ff24d0;
}
.theme-skilldarkblue .skill_item:hover {
  background: #183e65;
}
.theme-skillblack .skill_item:hover {
  background: #212529;
}

.banner-right {
  margin-top: 6.25rem;
}

.banner-left h1 {
  color: #000;
  font-size: 2.25em;
  font-weight: 100;
  line-height: 1em;
  margin-bottom: 2.5rem;
}
.banner-left h1 span {
  text-transform: uppercase;
  font-weight: 700;
}

.header-btn {
  background-color: #f8faff;
  padding: 0.5rem 2.5rem;
  font-weight: 600;
  color: #000;
  box-shadow: 0rem 1.25rem 1.25rem 0rem rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  border-radius: 20px;
}
.header-btnhover {
  border: 1px solid #fff;
  background: transparent;
}

@media screen and (max-width: 1024px) {
  .banner-left h1 {
    font-size: 2.813em;
  }
}
@media screen and (max-width: 896px) {
  .banner-left {
    text-align: center;
  }
  .banner-left h1 {
    font-size: 2.25em;
  }
  .banner-left h1 br {
    display: none;
  }

  .banner-right img {
    width: 65%;
    margin-bottom: 2rem;
    margin-right: auto !important;
    margin-left: auto !important;
    display: block;
  }
}
@media screen and (max-width: 767.98px) {
  .banner-left {
    padding-bottom: 2.5rem;
  }

  .banner-right {
    margin-top: 4.375rem;
    margin-bottom: 1.875rem;
  }
  .banner-right img {
    width: 75%;
    margin-right: auto !important;
    margin-left: auto !important;
    display: block;
  }
}
@media screen and (max-width: 425px) {
  .banner-left h1 {
    font-size: 1.5em !important;
  }
}