
    $mainColor : #F4F0EC;
    $contrast : #F0C0B2;
    $nuance : #D8D8D9;
    $secondNuance : #B3BAC4;
    $thirdNuance : #3F5160;
    $lightBlue: #A5D4FA;
    $black: rgba(23, 24, 25, 1);
    $lightWhite: #FFFFFFE6;


