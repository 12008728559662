@import "libs/palette";
.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #Fff;
    animation: loader 2s infinite ease;
}
.loader-wrapper {
    z-index: 1000;
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left: 0;
    background-color: $thirdNuance ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

#dots5 {
    display: block;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    position: absolute;
    top: 50%;
    width: 50px;

    span {
        -webkit-animation: dots5 1s ease-in-out infinite;
        animation: dots5 1s ease-in-out infinite;
        background: rgba(0,0,0,.25);
        border-radius: 50%;
        height: 10px;
        position: absolute;
        width: 10px;
        &:first-child {
            -webkit-animation-delay: .2s;
            animation-delay: .2s;
            left: 0;
        }
        &:nth-child(2) {
        -webkit-animation-delay: .3s;
        animation-delay: .3s;
        left: 15px;
    }
        &:nth-child(3) {
            -webkit-animation-delay: .4s;
            animation-delay: .4s;
            left: 30px;
        }
        &:nth-child(4) {
            -webkit-animation-delay: .5s;
            animation-delay: .5s;
            left: 45px
        }
    }

}

@keyframes dots5 {
    0% {
        background: #f4f0ec;
        transform: translateY(0)
    }

    25% {
        background: #f0c0b2;
        transform: translateY(10px)
    }

    50% {
        background: #a5d4fa;
        transform: translateY(10px)
    }

    to {
        background: #b3bac4;
        transform: translateY(0)
    }
}

@-webkit-keyframes dots5 {
    0% {
        background: #f4f0ec;
        transform: translateY(0)
    }

    25% {
        background: #f0c0b2;
        transform: translateY(10px)
    }

    50% {
        background: #a5d4fa;
        transform: translateY(10px)
    }

    to {
        background: #b3bac4;
        transform: translateY(0)
    }
}

