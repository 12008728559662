@import "libs/palette";

$header-height: 3rem;
$nav-width: 4.25rem;
$normal-font-size: 1rem;
$z-fixed: 100;


body {
    position: relative;
    padding: $header-height 1rem 0 1rem;
    font-family: sans-serif;
    transition: .5s;
    min-width: 320px;
    overflow-x: hidden;
    min-height: 100vh;
    background-attachment: fixed;
    
background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='69.141' height='40' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M69.212 40H46.118L34.57 20 46.118 0h23.094l11.547 20zM57.665 60H34.57L23.023 40 34.57 20h23.095l11.547 20zm0-40H34.57L23.023 0 34.57-20h23.095L69.212 0zM34.57 60H11.476L-.07 40l11.547-20h23.095l11.547 20zm0-40H11.476L-.07 0l11.547-20h23.095L46.118 0zM23.023 40H-.07l-11.547-20L-.07 0h23.094L34.57 20z'  stroke-width='1' stroke='hsla(0, 0%, 0%, 0.05)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>")
}

a {
    text-decoration: none;
}

.header {
    width: 100%;
    height: $header-height;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: $nuance;
    z-index: $z-fixed;
    transition: .5s
}

.header_toggle {
    color: $thirdNuance;
    font-size: 1.5rem;
    cursor: pointer;
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: $nav-width;
    height: 100vh;
    background-color: $thirdNuance;
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: $z-fixed;
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;
}

.nav_logo {
    margin-bottom: 2rem;
}


.nav_logo-icon {
    font-size: 1.25rem;
    color: white;
}

.nav_logo-name {
    color:white;
    font-weight: 700;
}

.nav_link {
    position: relative;
    color: $black;
    margin-bottom: 1.5rem;
    transition: .3s;
    &:hover {
        color: $lightWhite;
    }
}


.nav_icon {
    font-size: 1.25rem;
}

.show {
    left: 0;
}

.body-pd {
    padding-left: $nav-width + 1rem;
}

.active {
    color: white;
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: white;
}

.height-100 {
    height: 100vh;
}

@media screen and (min-width: 768px) {
    body {
        padding-top: ($header-height + 1rem);
        padding-left: $nav-width + 2rem;
    }

    .header {
        height: $header-height + 1rem;
        padding: 0 2rem 0 ($nav-width + 2rem);
    }


    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0
    }

    .show {
        width: ($nav-width + 9.75rem)
    }

    .body-pd {
        padding-left: ($nav-width + 11.75rem)
    }
}
