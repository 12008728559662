@import "libs/palette";
@import "libs/responsive";
@import "libs/mixins";
@import "libs/function";
@import "sweetalert2/dist/sweetalert2.css";
@import "~boxicons/css/boxicons.css";
@import "~aos/dist/aos.css";
@import "highlight.js/styles/monokai-sublime.css";

a {
    text-decoration: none!important;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: AlegreyaSans-Regular,sans-serif;
    font-size: $base-font-size;

    &:focus {
        outline: none !important;
    }
}

body{
    background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='69.141' height='40' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M69.212 40H46.118L34.57 20 46.118 0h23.094l11.547 20zM57.665 60H34.57L23.023 40 34.57 20h23.095l11.547 20zm0-40H34.57L23.023 0 34.57-20h23.095L69.212 0zM34.57 60H11.476L-.07 40l11.547-20h23.095l11.547 20zm0-40H11.476L-.07 0l11.547-20h23.095L46.118 0zM23.023 40H-.07l-11.547-20L-.07 0h23.094L34.57 20z'  stroke-width='1' stroke='hsla(259, 0%, 94%, 0.64)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
}

#mention-border {
    border: 1px solid black;
    padding: 1rem;
}
::before,
::after {
    box-sizing: border-box
}
html {
    height: 100vh;
    scroll-behavior: smooth;
}
.border-50 {
    border-radius: 50%;
}

.logo {
    width: 20em;
}
.text-slider-items {
    display: none;
}

#textProfil {
    p {
        color: black !important;
    }
}
.section {
    overflow-x: hidden;
    margin-bottom: 5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.no-x-overflow {
    overflow-x: hidden !important;
}
/* --- Modifier le style de la barre de defilement --- */
::-webkit-scrollbar
{
    width: 0.75rem;
}
::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 2px $thirdNuance;
}
::-webkit-scrollbar-thumb
{
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,1);
    background-color:  $thirdNuance;
}
.bx-xl {
    font-size: 5rem!important;
}
#form-contact {
    padding: 1rem;
    input,select,textarea {
        border-radius: 0!important;
        outline: none;
        box-shadow: none;
        &:focus {
            border: 3px solid $thirdNuance;
        }
    }
}


/* --- Scroll to top  button --- */
.scroll-to-top {
    position: fixed;
    display: none;
    right: $button-scroll-right;
    width: $button-scroll-size;
    height: $button-scroll-size;
    text-align: center;
    bottom: $button-scroll-right;
    color: white;
    background: rgba($black, 0.5);
    line-height: em(2.8);
    z-index: 5;

    &:focus {
        color: white;
    }
    &:hover {
        color: white;
        background: $black;
    }
    i {
        font-weight: 800;
    }
}

.center-box {
    display: block;
    width: 58%;
    max-width: 925px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    text-align: center;
}

@media screen and (max-width: 991px) {
    .center-box {
        width: 78%;
    }
}
@media screen and (max-width: 767px) {


    .center-box {
        width: 85%;
    }
    .section {
        margin-top: 6rem;
    }
}
@media screen and (max-width: 479px) {
    .center-box {
        width: 90%;
    }
    .border-50 {
        border-radius: 50%;
        height: 18rem;
        width: 18rem;
    }

    }

.badge-certification {
    width: 10rem;
    height: 10rem;
    margin: 1rem 0
}

.row > * {
    a {
        width: unset !important;
    }
}

.content-wrapper {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100%;
    min-width: 75vw;
    min-height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 25px;
    padding-left: 25px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    .flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

h1 {
    margin-bottom: 3rem;
}
@import "navbar";
@import "footer";
@import "splash";
@import "skills";

