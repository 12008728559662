@import "libs/responsive";

$themes: (
        skillred: (
                primary: #e70f16
        ),
        skilllightblue: (
                primary: #6495ed
        ),
        skillblue: (
                primary:#49b0f0
        ),
        skillgray: (
                primary: #878e93
        ),
        skillmediumblue: (
                primary: #0872c4
        ),
        skillyellow: (
                primary:#ffc107
        ),
        skillorange: (
                primary: #fa3f06
        ),
        skillgreen: (
                primary: #50bd13
        ),
        skillpurple: (
                primary: #b447eb
        ),
        skillpink: (
                primary: #ff24d0
        ),
        skilldarkblue: (
                primary: #183e65
        ),
        skillblack: (
                primary: #212529
        )


);

@mixin theme($themes: $themes) {
  @each $theme, $colors in $themes {
    .theme-#{$theme} & {
      @content($colors);
    }
  }
}

.card-item-top {
  @include theme using ($colors) {
    border-top: 0.5em solid map-get($colors, primary);
  }
}
.card-icon {

  font-size: 5rem!important;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  @include theme using ($colors) {
    color: map-get($colors, primary);
  }

}
.skill_item {
  border-top: .5em solid #e70f16;
  padding: 60px 30px;
  text-align: center;
  background: #f8faff;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;

  img {
    margin-bottom: 2.5rem;
  }

  h2 {
    color: #05364d;
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 1.25rem;
    text-transform: capitalize;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  p {
    margin-bottom: 0rem;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

  }

  &:hover {
    h2 {
      color: #ffffff;
    }
    p {
      color: #ffffff;
    }
    .card-icon {
      color: #ffffff;
    }
    @include theme using ($colors) {
      background: map-get($colors, primary);
    }
    transition: all 0.4s ease 0s;
  }

}

.banner-right {
  margin-top: 6.25rem;
}

.banner-left {

  h1 {
    color: #000;
    font-size: 2.25em;
    font-weight: 100;
    line-height: 1em;
    margin-bottom: 2.5rem;

    span {
      text-transform: uppercase;
      font-weight: 700;
    }

  }
}

.header-btn {
  background-color: #f8faff;
  padding: 0.5rem 2.5rem;
  font-weight: 600;
  color: #000;
  box-shadow: 0rem 1.25rem 1.25rem 0rem rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  border-radius: 20px;
  &hover {
    border: 1px solid #fff;
    background: transparent;

  }
}

@media #{$pc-down} {

  .banner-left {
    h1 {
      font-size: 2.813em;
    }
  }
}

@media #{$inter-down} {
  .banner-left {
    text-align: center;
    h1 {
      font-size: 2.25em;
      br {
        display: none;
      }
    }
  }

  .banner-right {
    img {
      width: 65%;
      margin-bottom: 2rem;
      margin-right: auto !important;
      margin-left: auto !important;
      display: block;
    }
  }
}

@media #{$menu-down} {
  .banner-left {
    padding-bottom: 2.5rem;
  }
  .banner-right {
    margin-top: 4.375rem;
    margin-bottom: 1.875rem;

    img {
      width: 75%;
      margin-right: auto !important;
      margin-left: auto !important;
      display: block;
    }
  }


}

@media #{$smartphone-down} {
  .banner-left {
    h1 {
      font-size: 1.5em !important;
    }
  }

}