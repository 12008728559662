/* --- Scroll to top  button --- */

.scroll-to-top {
    position: fixed;
    right: 15px;
    bottom: 15px;
    display: none;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 10rem!important;
    color: white;
    background: rgba(52, 58, 64, 0.5);
    line-height: 45px;
    z-index: 5;

}

.scroll-to-top:focus, .scroll-to-top:hover {
    color: white;
}

.scroll-to-top:hover {
    background: #343a40;
}

.scroll-to-top i {
    font-weight: 800;
}
